.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

.table-action i {
  position: inherit;
  top: 2px;
}

.divider-title {
  border-bottom: 1px dashed #c1c1c1;
  color: #193550;
  font-weight: bold;
  font-size: 16px;
  margin: 15px 0px 5px;
  width: 100%;
}

.ord-file-dinh-kem {
  width: 200px;
  margin: 0 auto;
  display: block;
  position: relative;
  background-color: #cccccc;
}

.ord-file-dinh-kem .btnGroup .btn-success {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent;
  border: 1px solid;
}

.ord-file-dinh-kem .btnGroup .btn-success span {
  position: absolute;
  bottom: 0;
  background: transparent;
  z-index: 3;
  width: 100%;
  left: 0;
  padding: 5px;
  color: #fff;
  visibility: hidden;
}

.ord-file-dinh-kem .btnGroup .btn-success:focus {
  background-color: transparent !important;
}

.ord-file-dinh-kem .btnGroup .btn-success:hover {
  background-color: transparent !important;
  border-color: #0bb7af;
}

.ord-file-dinh-kem .btnGroup .btn-success:hover span {
  background: #0bb7af;
  visibility: visible;
}

.ord-file-dinh-kem .btnGroup .btn-danger {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: 0.02em;
}
/*# sourceMappingURL=custom.css.map */